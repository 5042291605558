import React from 'react';
import { Menu } from 'antd';

const { SubMenu } = Menu;

const QuestionList = ({ questions, onSelect }) => {
  const categories = questions.reduce((acc, question) => {
    if (!acc[question.category]) {
      acc[question.category] = [];
    }
    acc[question.category].push(question);
    return acc;
  }, {});

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <Menu mode="inline" style={{ borderRight: 0, height: '100%' }}>
          {Object.keys(categories).map(category => (
            <SubMenu key={category} title={category}>
              {categories[category].map(question => (
                <Menu.Item key={question.id} onClick={() => onSelect(question.id)}>
                  {question.title}
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </div>
      {/*<div style={{ flexShrink: 0, textAlign: 'center', padding: '10px', background: '#fff' }}>*/}
      {/*  <a href="http://ququsql.cn/" target="_blank" rel="noopener noreferrer">*/}
      {/*    前往学习区区SQL教程*/}
      {/*  </a>*/}
      {/*  <br />*/}
      {/*  <a href="https://www.xiaohongshu.com/user/profile/5c6ab9bc0000000011020a20" target="_blank" rel="noopener noreferrer">*/}
      {/*    关注小红书获取最新更新*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
};

export default QuestionList;
